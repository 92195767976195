<template>
    <div class="viewBox">
      <!-- top -->
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>专家管理</el-breadcrumb-item>
            <el-breadcrumb-item @click.native="goback1">专家列表</el-breadcrumb-item>
            <el-breadcrumb-item>专家详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="top" v-if="info">
            <BlueBox :msg="msg1"/>
            <div class="topRight">
                <div v-if="info">
                    <span class="spant1">用户状态</span><span class="spant2">{{info.status}}</span>
                </div>
                <div class="notAllow" v-if="info.status=='启用'" @click="disble">禁用</div>
                <div class="notAllow" v-if="info.status=='禁用'" style="background-color:#00CCCC" @click="disbleN">解除禁用</div>
            </div>
        </div> 
        <div class="baseBox">
            <div class="title">
                <div class="circle"></div>
                <span class="baseTitle">基本信息</span>
            </div>
            <div class="dataBox" v-if="info">
                <div class="info">
                    <div class="infoLine"><span class="fromT">姓名</span><span class="backT">{{info.name}}</span></div>
                    <div class="infoLine"><span class="fromT">账号创建时间</span><span class="backT">{{info.crateTime}}</span></div>
                    <div class="infoLine"><span class="fromT">所属机构</span><span class="backT">{{info.orgName}}</span></div>
                </div>
                <!-- center -->
                <div class="info">
                    <div class="infoLine"><span class="fromT">性别</span><span class="backT" v-if="(info.gender =='1')">男</span><span class="backT" v-if="(info.gender==0)">女</span></div>
                    <div class="infoLine"><span class="fromT">证件类型</span><span class="backT">{{info.cardType}}</span></div>
                    <div class="infoLine"><span class="fromT">所属科室</span><span class="backT">{{info.deptName}}</span></div>
                </div>
                <!-- right -->
                <div class="info" style="margin-right:200px">
                    <div class="infoLine"><span class="fromT">手机号码</span><span class="backT">{{info.mobile}}</span></div>
                    <div class="infoLine"><span class="fromT">证件号码</span><span class="backT">{{info.cardId}}</span></div>
                    <div class="infoLine"><span class="fromT">职位</span><span class="backT">{{info.jobTitle}}</span></div>
                </div>
            </div>
        </div>
        <!-- best -->
        <div class="bestBox" v-if="info">
            <BlueBox :msg="msg2"/>
            <div class="descBox">{{(info.doctorDesc) ? info.doctorDesc : '暂无'}}</div>
        </div>
        <!-- work-time -->
        <div class="bestBox2" v-if="info">
            <BlueBox :msg="msg3"/>
            <div class="descBox2">{{(info.startWorkDate) ? info.startWorkDate : '--'}}</div>
        </div>
        <!--certify-->
        <div class="certifyBox" v-if="info">
            <BlueBox :msg="msg4"/>
            <!-- car---- -->
            <div class="certLine" v-for="(item,index) in imgList" :key="index" >
                <div class="certyTitle">{{item.certName}}</div>
                <div class="crtyTime">{{item.startTime}}——{{item.endTime}}</div>
                <div class="imgBox">

                <viewer v-if="item.image!=null" :images="[item.image]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                      <img class="image" alt="" :src="item.image">
                    </el-tooltip>
                </viewer>
                <viewer v-if="item.image2!=null" :images="[item.image2]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                      <img class="image" alt="" :src="item.image2">
                    </el-tooltip>
                </viewer>
                <viewer v-if="item.image3!=null" :images="[item.image3]">
                    <el-tooltip class="item" effect="dark" content="点击查看大图" placement="right">
                      <img class="image" alt="" :src="item.image3">
                    </el-tooltip>
                </viewer>
                </div>
            </div>
        </div>
         <!-- 启用和禁用弹窗 -->
      <el-dialog
        :show-close="false"
        :visible.sync="showProp"
        width="432px"
        center>
       <!-- 内容区 -->
        <div class="showBox" v-if="info">
            <div class="conten">
                <span v-if="(info.status=='启用')">是否要禁用该账号？禁用后该账号专家将无法正常登录！</span>
                <span  v-if="(info.status!='启用')">确认要解除<span style="color:#00cccc">{{info.name}}</span>专家的禁用状态？</span>
            </div>
            <div class="sureBotton" @click="closeOpen">确认</div>
        </div>

      </el-dialog>
        
    </div>
  </template>
  <script>
  import BlueBox from '@/components/other/blueBox.vue'
  import {viewDotor2,updateStatus} from '@/api/expert.js'
  export default {
    beforeRouteLeave(to,from,next){
      if(to.name === 'ExpertList'){
        to.meta.keepAlive = true;
      }
      next();
    },
    components:{
        BlueBox
    },
      data(){
        return{
            id:null,
            msg1:'专家详情',
            msg2:'专业擅长',
            msg3:'从业时间',
            msg4:'相关证书',
            imgList:null,
            info:null,
            showProp:false,
            isOpen:false,
        }
      },
      created(){
        if(this.$route.query.id){
            this.id = this.$route.query.id;
            this.getData(this.id);
      }
      },
      methods:{
        //面包屑
        goback1(){
            this.$router.go(-1);
        },
        //getData
        getData(id){
            viewDotor2({
                id:id
            }).then((res)=>{
                  if(res.data.status == 200){
                    this.info = res.data.data;
                    this.imgList  = res.data.data.certificates;
                  }else{
                    this.$message.error(res.data.message);
                  }
                }).catch(res=>{
                   this.$message.error(res);
                })
        },
        disble(){
            this.showProp= true;  
        },
        disbleN(){
            this.showProp= true;
        },
        closeOpen(){
            if(this.info.status=='启用'){
                updateStatus({
                id:this.id,
                status:'禁用'
            }).then((res)=>{
                  if(res.data.status == 200){
                    this.$message({
                    message: '已禁用！',
                    type: 'success'
                    });
                    this.showProp= false;
                    this.getData(this.id);
                  }else{
                    this.$message.error(res.data.message);
                  }
                }).catch(res=>{
                   this.$message.error(res);
                })
            }else{
                updateStatus({
                id:this.id,
                status:'启用'
            }).then((res)=>{
                  if(res.data.status == 200){
                    this.$message({
                    message: '已启用',
                    type: 'success'
                    });
                    this.showProp= false;
                    this.getData(this.id);
                  }else{
                    this.$message.error(res.data.message);
                  }
                }).catch(res=>{
                   this.$message.error(res);
                })
            }
            
        }
      }
  }
  </script>
  <style scoped lang="stylus">
  //change elemen-ui style
  @import '~@/assets/css/newTask.css'
  /deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner{
    font-weight: bold !important;
  }
.viewBox
    overflow hidden
    .top
        margin-top: 36px 
        display: flex
        justify-content: space-between
    .topRight
        display: flex
        align-items: center
        .spant1
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #323233;
            line-height: 22px;
        .spant2
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            margin-left: 16px
            line-height: 22px;
        .notAllow
            width: 140px;
            height: 34px;
            background: #FC5555;
            margin-left: 50px
            border-radius: 6px;
            font-size: 14px;
            text-align: center
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 34px;
            cursor pointer;
    .baseBox
        width: 100%
        height: 252px 
        margin-top: 20px
        border-radius: 8px 
        background-color: #F4FBFE
        box-sizing: border-box
        padding: 30px 32px  
        .title
            display: flex
            align-items: center
            .circle
                width: 10px
                height: 10px
                border-radius: 50%
                margin-left: -16px
                background-color: #00cccc
            .baseTitle
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight:bold;
                color: #323233;
                line-height: 22px;
                margin-left: 6px
        .dataBox
            margin-top: 36px
            width: 100%
            height: 140px
            display: flex
            justify-content: space-between
            .info
                display: flex
                flex-direction: column
                justify-content: space-between
                .infoLine
                    display: flex
                    align-items: center
                    .fromT 
                        font-weight: 500;
                        font-family: PingFangSC-Medium, PingFang SC;
                        color: #353535;
                        font-size: 16px
                    .backT
                        color: #858585;
                        line-height: 20px;
                        margin-left: 20px;
                        font-size: 14px;
    .bestBox
        width: 100%
        margin-top: 40px  
        .descBox
            width: 100%
            margin-top: 20px
            font-size: 14px;
            font-weight: 400;
            color: #858585;
    .bestBox2
        width: 100%
        margin-top: 40px  
        .descBox2
            width: 100%
            margin-top: 20px
            font-size: 14px;
            font-weight: 400;
            color: #858585;
    .certifyBox
        width: 100%
        margin-top: 40px
        margin-bottom: 20px
        .certLine
            margin-bottom: 40px 
            .certyTitle
                font-size: 13px;
                font-weight: 500;
                color: #333333;
                line-height: 18px;
            .crtyTime
                font-size: 13px;
                margin-top: 6px
                font-weight: 400;
                color: #666666;
                line-height: 18px;
            .imgBox
                width: 100%
                display: flex
                justify-content: space-between
                margin-top: 12px
                .image
                    width:2.53rem 
                    height:1.86rem
                    object-fit: cover
.showBox
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    .conten
        width 270px 
        height: 48px 
        font-size: 16px;
        text-align: center
        font-weight: 400;
        color: #353535;
        line-height: 24px;
    .sureBotton
        width: 120px;
        height: 32px;
        line-height: 32px
        margin-top: 24px
        text-align: center
        background: #00CCCC;
        border-radius: 4px;
        border: 1px solid #00CCCC;
        font-size: 14px;
        font-weight: 500;
        color: #FFFFFF;
        cursor pointer



      
  
  
  </style>